import React from "react";

const Preloader = () => {
      // useEffect((event)=>{
  //   const preloader = document.querySelector('#preloader');
  //   if (preloader) {
  //     window.addEventListener('load', () => {
  //       preloader.remove();
  //     });
  //   }
  
  // },[])
  return <div id="preloader"></div>;
};

export default Preloader;
